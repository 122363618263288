[data-component=user] {
  margin-left: auto;
}

.user-component {
  font-family: var(--permobil-navigator-font-family);
  position: relative;
}
.user-component * {
  font-family: var(--permobil-navigator-font-family);
}
.user-component__modal {
  font-family: var(--permobil-navigator-font-family);
}
.user-component__modal * {
  font-family: var(--permobil-navigator-font-family);
}
.user-component__modal .__booya-profile-form {
  padding: 40px;
  width: 320px;
  max-width: none;
}
.user-component__auth-button {
  background: none;
  border: none;
  color: #414042;
  cursor: pointer;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  padding: 8px 10px;
  margin: 0 0 0 14px;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .user-component__auth-button {
    display: none;
  }
}
.user-component__auth-button:hover {
  background: none;
  color: var(--permobil-royal-blue, #0067A6);
}
.user-component__auth-button:focus {
  background: none;
  color: var(--permobil-royal-blue, #0067A6);
  outline-width: 0;
}
.user-component__dropdown {
  background: #FFFFFF;
  border-radius: 0px;
  box-shadow: inset 0px 4px 0px #0067A6;
  left: 0;
  padding: 32px;
  position: absolute;
  top: calc(100% + 4px);
}
@media (max-width: 1023px) {
  .user-component__dropdown {
    padding: 24px;
  }
}
.user-component__dropdown ul {
  min-width: 108px;
  margin: 0;
  padding: 0;
}
.user-component__dropdown ul li {
  border-bottom: 1px solid rgba(65, 64, 66, 0.1);
  list-style-type: none;
  margin: 0 0 12px;
  padding: 0 0 12px;
}
.user-component__dropdown ul li a {
  align-items: center;
  color: #191919;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  justify-content: flex-start;
  line-height: normal;
  text-decoration: none;
}
.user-component__dropdown ul li a:hover {
  color: var(--permobil-royal-blue, #0067A6);
  text-decoration: none;
}
.user-component__dropdown ul li a svg {
  margin-right: 6px;
}
.user-component__dropdown ul li:last-of-type {
  border-bottom: none;
  margin: 0;
  padding: 0;
}
.user-component__dropdown--left {
  left: 0;
  right: auto;
}
@media (max-width: 576px) {
  .user-component__dropdown--left {
    left: -20px;
  }
}
.user-component__dropdown--right {
  right: 0;
  left: auto;
}
@media (max-width: 576px) {
  .user-component__dropdown--right {
    right: -20px;
  }
}
.user-component__dropdown--center {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.user-component__user-button {
  align-items: center;
  background: #F5F8FA;
  border: none;
  border-radius: 0px;
  color: #0067A6;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0 0 0 50px;
  padding: 8px;
}
.user-component__user-button:hover {
  background: #F5F8FA;
  color: #0067A6;
}
.user-component__user-button:hover .user-component__user-button-name {
  color: #0067A6;
}
.user-component__user-button:focus {
  background: #EDF8FF;
  color: #0067A6;
  outline-width: 0;
}
.user-component__user-button:focus .user-component__user-button-name {
  color: #0067A6;
}
.user-component__user-button--opened {
  background: #EDF8FF;
}
.user-component__user-button--opened .user-component__user-button-name {
  color: #0067A6;
}
@media (max-width: 1023px) {
  .user-component__user-button {
    padding: 6px;
    border-radius: 36px;
  }
}
.user-component__user-button-avatar {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: block;
  height: 32px;
  margin-right: 6px;
  min-width: 32px;
  width: 32px;
}
@media (max-width: 1023px) {
  .user-component__user-button-avatar {
    height: 24px;
    min-width: 24px;
    width: 24px;
  }
}
@media (max-width: 576px) {
  .user-component__user-button-avatar {
    margin-right: 0;
  }
}
.user-component__user-button-name {
  display: block;
  color: var(--permobil-charcoal, #414042);
  font-weight: 500;
  font-size: 13px;
  line-height: normal;
  margin-right: 8px;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .user-component__user-button-name {
    display: none;
  }
}

/*.__booya-banner {
    display: none !important;
}*/